<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          icon
          v-bind="attrs"
          v-on="on">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
      <alert-remove-card
        @confirm="submit()"
        @close="closeDialog()" />
    </v-dialog>
  </div>
</template>

<script>
import AlertRemoveCard from '@/components/Card/AlertRemoveCard.vue'
import { DELETE_PROMOCODE } from '@/resources/graphql'

export default {
  name: 'PromocodeRemove',
  components: {
    AlertRemoveCard
  },
  props: {
    promoId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      await this.$apollo.mutate({
        mutation: DELETE_PROMOCODE,
        variables: {
          input: {
            id: this.promoId
          }
        }
      })
      this.$emit('submit')
      this.closeDialog()
    }
  }
}
</script>
