import gql from 'graphql-tag'

export const GET_PROMOCODE = gql`
  query ($id: ID!) {
    promoCode(id: $id) {
      id
      minRedeemValue
      discountType
      minRedeemType
      claimable
    }
  }
`

export default {
  GET_PROMOCODE
}
