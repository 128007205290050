<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="promoCodes.nodes"
      class="app-table"
      hide-default-footer>
      <template v-slot:[`header.code`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`header.date`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`header.discountValue`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`header.minRedeemValue`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`header.pointUsed`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`header.usedCount`]="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ item.startDate | formatDateNoTime }} - {{ item.endDate | formatDateNoTime }}
      </template>
      <template v-slot:[`item.discountValue`]="{ item }">
        {{ item.discountValue.toFixed(2) }}  
        <span v-if="item.discountType == 'baht'">
          {{ $t('promocode.table.currency.baht') }}
        </span>
        <span v-else>
          {{ $t('promocode.table.currency.percent') }}
        </span>
      </template>
      <template v-slot:[`item.minRedeemValue`]="{ item }">
        <div class="d-flex align-center justify-space-between">
          <span v-if="item.minRedeemValue || item.minRedeemValue === 0">
            {{ $t('promocode.table.minValue') }} 
            {{ item.minRedeemValue.toFixed(2) }}
            <span v-if="item.minRedeemType == 'count_value'">
              {{ $t('promocode.table.minRedeem.value') }}
            </span>
            <span v-else>
              {{ $t('promocode.table.minRedeem.quantity') }}
            </span>
          </span>
          <span v-else>
            ไม่มีขั้นต่ำ
          </span>
          <promocode-update
            :promo-id="item.id"
            @submit="refetch()">
          </promocode-update>
        </div>
      </template>
      <template v-slot:[`item.pointUsed`]="{ item }">
        {{ $t('promocode.table.used') }}
        {{ item.pointUsed.toFixed(2) }}
        {{ $t('promocode.table.point') }}
      </template>
      <template v-slot:[`item.usedCount`]="{ item }">
        {{ item.usedCount }} /
        <span
          v-if="item.maxClaimed"
          class="ml-2">
          {{ item.maxClaimed }}
        </span>
        <span v-else>
          {{ $t('promocode.table.infinite') }}
        </span>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <promocode-remove
          :promo-id="item.id"
          @submit="refetch()">
        </promocode-remove>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      :length="promoCodes.pagesCount"
      :total-visible="7">
    </v-pagination>
  </div>
</template>

<script>
import PromocodeUpdate from '@/views/ShopSetting/Pages/Promocode/components/PromocodeUpdate.vue'
import PromocodeRemove from '@/views/ShopSetting/Pages/Promocode/components/PromocodeRemove.vue'
import { GET_PROMOCODES } from '@/resources/graphql/query/Promocodes'

export default {
  name: 'PromocodeTable',
  apollo: {
    promoCodes: () => ({
      fetchPolicy: 'no-cache',
      query: GET_PROMOCODES,
      variables () {
        return {
          page: this.page,
          perPage: this.perPage,
          claimableFilter: this.claimableFilter,
          activeFilter: this.activeFilter,
          code: this.code,
          orderBy: this.orderBy
        }
      },
      result ({ data: { promoCodes } }) {
        if (this.page > promoCodes.pagesCount) {
          this.page -= 1
        }
      }
    })
  },
  components: {
    PromocodeUpdate,
    PromocodeRemove
  },
  props: {
    updateModal: {
      type: Boolean,
      default: false
    },
    claimableFilter: {
      type: String,
      default: ''
    },
    activeFilter: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      promoCodes: [],
      page: 1,
      perPage: 5,
      orderBy: 'id_DESC',
      headers: [
        {
          text: this.$t('promocode.table.header.code'),
          align: 'start',
          value: 'code',
          sortable: true
        },
        {
          text: this.$t('promocode.table.header.rangeDate'),
          align: 'start',
          value: 'date',
          sortable: true
        },
        {
          text: this.$t('promocode.table.header.discount'),
          align: 'start',
          value: 'discountValue',
          sortable: false
        },
        {
          text: this.$t('promocode.table.header.minValue'),
          align: 'start',
          value: 'minRedeemValue',
          sortable: false
        },
        {
          text: this.$t('promocode.table.header.point'),
          align: 'start',
          value: 'pointUsed',
          sortable: false
        },
        {
          text: this.$t('promocode.table.header.used'),
          align: 'start',
          value: 'usedCount',
          sortable: false
        },
        {
          text: '',
          align: 'start',
          value: 'delete',
          sortable: false
        }
      ]
    }
  },
  methods: {
    openPromocodeUpdate () {
      this.$emit('update:updateModal', true)
    },
    refetch () {
      this.$apollo.queries.promoCodes.refetch()
    }
  }
}
</script>

<style>

</style>
