<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500"
      @click:outside="closePromocodeCreate()">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          rounded
          depressed
          v-bind="attrs"
          v-on="on">
          <v-icon class="mr-2">
            mdi-plus-circle
          </v-icon>
          <span>
            {{ $t('promocode.btn.addPromocode') }}
          </span>
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="pt-3">
          <div class="close-popup">
            <v-btn
              small
              icon
              @click="closePromocodeCreate()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <title-label :label="$t('promocode.label.createPromocode')" />
          <v-form
            ref="form"
            v-model="valid"
            :readonly="loading"
            class="mt-3"
            lazy-validation
            @submit.prevent="submit()">
            <v-row no-gutters>
              <v-col>
                <text-field-label
                  :label="$t('promocode.textField.code.label')">
                </text-field-label>
                <v-text-field
                  v-model="form.code"
                  :placeholder="$t('promocode.textField.code.placeholder')"
                  :rules="codeRules"
                  append-icon="mdi-dice-5-outline"
                  dense
                  outlined
                  required
                  @click:append="generateCode()" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <text-field-label
                  :label="$t('promocode.textField.discount.label')">
                </text-field-label>
                <v-text-field
                  v-model="form.discountValue"
                  :placeholder="$t('promocode.textField.discount.placeholder')"
                  :rules="discountRules"
                  dense
                  outlined
                  required />
              </v-col>
              <v-col class="pl-5">
                <text-field-label
                  :label="$t('promocode.textField.discountType.label')">
                </text-field-label>
                <v-select
                  v-model="form.discountType"
                  :placeholder="$t('promocode.textField.discountType.placeholder')"
                  :items="discountType"
                  item-text="description"
                  item-value="name"
                  attach
                  dense
                  outlined>
                </v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <text-field-label
                  :label="$t('promocode.textField.minType.label')">
                </text-field-label>
                <v-select
                  v-model="form.minRedeemType"
                  :placeholder="$t('promocode.textField.minType.placeholder')"
                  :items="minRedeemType"
                  item-text="description"
                  item-value="name"
                  attach
                  dense
                  outlined>
                </v-select>
              </v-col>
              <v-col class="pl-5">
                <text-field-label
                  :label="minValueLabel">
                </text-field-label>
                <v-text-field
                  v-model="form.minRedeemValue"
                  :placeholder="$t('promocode.textField.minPrice.placeholder')"
                  :rules="minValueRules"
                  dense
                  outlined
                  required />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <text-field-label
                  :label="$t('promocode.textField.startEnd.label')">
                </text-field-label>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="displayRangeDate()"
                      :placeholder="$t('promocode.textField.startEnd.placeholder')"
                      :rules="dateRules"
                      dense
                      outlined
                      readonly
                      v-bind="attrs"
                      required
                      v-on="on" />
                  </template>
                  <v-date-picker
                    v-model="form.rangeDate"
                    range>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <text-field-label
                  :label="$t('promocode.textField.maxClaimed.label')">
                </text-field-label>
                <v-text-field
                  v-model="form.maxClaimed"
                  dense
                  outlined>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <text-field-label
                  :label="$t('promocode.textField.promocodeType.label')">
                </text-field-label>
                <v-radio-group
                  v-model="form.claimable"
                  dense
                  row>
                  <v-radio
                    :label="$t('promocode.radio.claimCode')"
                    :value="true"
                    style="margin-right: 50%;">
                  </v-radio>
                  <v-radio
                    :label="$t('promocode.radio.voucher')"
                    :value="false">
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <div v-if="form.claimable">
                  <text-field-label
                    :label="$t('promocode.textField.point.label')">
                  </text-field-label>
                  <v-text-field
                    v-model="form.pointUsed"
                    :rules="pointRules"
                    dense
                    outlined
                    required />
                </div>
                <div v-else>
                  <text-field-label
                    :label="$t('promocode.textField.prerequisiteId.label')">
                  </text-field-label>
                  <v-combobox
                    v-if="promoCodes"
                    v-model="form.prerequisite"
                    :items="promoCodes.nodes"
                    :rules="prerequisiteIdRules"
                    :menu-props="{ top: true, offsetY: true }"
                    item-text="code"
                    item-value="id"
                    attach
                    dense
                    outlined
                    required>
                  </v-combobox>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <div class="d-flex justify-end mt-3">
            <v-btn
              color="primary"
              text
              @click="closePromocodeCreate()">
              {{ $t('promocode.btn.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              rounded
              depressed
              @click="submit()">
              {{ $t('promocode.btn.createPromocode') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_PROMOCODEDISCOUNTTYPE_ENUM, GET_PROMOCODEMINREDEEMTYPE_ENUM } from '@/resources/graphql/query/Enum'
import { CREATE_PROMOCODE } from '@/resources/graphql'
import { GET_PROMOCODES } from '@/resources/graphql/query/Promocodes'

export default {
  name: 'PromocodeCreate',
  apollo: {
    discountType: () => ({
      query: GET_PROMOCODEDISCOUNTTYPE_ENUM,
      result ({ data: { __type } }) {
        this.discountType = __type.enumValues
        this.form.discountType = this.discountType[0].name
      },
      update: (data) => data.__type
    }),
    minRedeemType: () => ({
      query: GET_PROMOCODEMINREDEEMTYPE_ENUM,
      result ({ data: { __type } }) {
        this.minRedeemType = __type.enumValues
        this.form.minRedeemType = this.minRedeemType[0].name
      },
      update: (data) => data.__type
    }),
    promoCodes: () => ({
      query: GET_PROMOCODES,
      variables () {
        return {
          perPage: 10000,
          claimableFilter: 'all',
          activeFilter: 'active'
        }
      },
      result () {
        this.promoCodes.nodes.unshift({
          id: '',
          code: '-'
        })
      }
    })
  },
  data () {
    return {
      menu: false,
      dialog: false,
      discountType: [],
      minRedeemType: [],
      promoCodes: [],
      form: {
        code: '',
        rangeDate: [],
        discountValue: '',
        discountType: '',
        minRedeemValue: null,
        minRedeemType: '',
        maxClaimed: '',
        claimable: false,
        pointUsed: '0',
        prerequisite: null
      },
      codeRules: [
        (v) => !!v || this.$t('promocode.textField.code.validate.required')
      ],
      discountRules: [
        (v) => !!v || this.$t('promocode.textField.discount.validate.required')
      ],
      minValueRules: [
        (v) => !!v || this.$t('promocode.textField.minPrice.validate.required')
      ],
      dateRules: [
        (v) => !!v || this.$t('promocode.textField.startEnd.validate.required')
      ],
      pointRules: [
        (v) => !!v || this.$t('promocode.textField.point.validate.required')
      ],
      prerequisiteIdRules: [
        (v) => !!v || this.$t('promocode.textField.prerequisiteId.validate.required')
      ],
      valid: true,
      state: 'ready'
    }
  },
  computed: {
    minValueLabel () {
      const minRedeemType = this.minRedeemType.find((item) => item.name === this.form.minRedeemType)
      if (minRedeemType) {
        return minRedeemType.description
      }
      return null
    },
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    generateCode () {
      const str = '0123456789abcdef'
      let code = ''
      for (let i = 0; i < 6; i++) {
        code += str.charAt(Math.floor(Math.random() * 16))
      }
      this.form.code = code.toUpperCase()
    },
    resetForm () {
      this.form = {
        code: '',
        rangeDate: [],
        discountValue: '',
        discountType: this.discountType[0].name,
        minRedeemValue: null,
        minRedeemType: this.minRedeemType[0].name,
        maxClaimed: '',
        claimable: false,
        pointUsed: '',
        prerequisite: ''
      }
    },
    displayRangeDate () {
      // if (this.$dayjs(this.form.rangeDate[1]).isBefore(this.$dayjs(this.form.rangeDate[0]))) {
      //   const endDate = this.form.rangeDate[1]
      //   this.form.rangeDate[1] = this.form.rangeDate[0]
      //   this.form.rangeDate[0] = endDate
      // }
      return this.form.rangeDate.join(' ~ ')
    },
    closePromocodeCreate () {
      this.dialog = false
      this.resetForm()
      this.$refs.form.resetValidation()
    },
    async submit () {
      if (this.$refs.form.validate()) {
        let preRequisite = ''
        if (this.form.prerequisite) {
          preRequisite = this.form.prerequisite.id
        }
        const res = await this.$apollo.mutate({
          mutation: CREATE_PROMOCODE,
          variables: {
            input: {
              code: this.form.code,
              startDate: this.form.rangeDate[0],
              endDate: this.form.rangeDate[1],
              discountValue: Number(this.form.discountValue),
              discountType: this.form.discountType,
              minRedeemValue: Number(this.form.minRedeemValue),
              minRedeemType: this.form.minRedeemType,
              maxClaimed: Number(this.form.maxClaimed),
              claimable: this.form.claimable,
              pointUsed: Number(this.form.pointUsed),
              prerequisiteId: preRequisite
            }
          }
        }).catch(() => {})
        if (res) {
          this.$emit('submit')
          this.dialog = false
          this.resetForm()
          this.$refs.form.resetValidation()
        }
      }
    }
  }
}
</script>

<style>

</style>
