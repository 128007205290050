import gql from 'graphql-tag'

export const GET_PROMOCODES = gql`
  query (
    $page: Int,
    $perPage: Int,
    $orderBy: PromoCodeOrderBy,
    $claimableFilter: PromoCodeClaimableFilterEnum,
    $activeFilter: PromoCodeActiveFilterEnum,
    $code: String
  ) {
    promoCodes(
      page: $page, 
      perPage: $perPage,
      orderBy: $orderBy,
      filter: { 
        claimableFilter: $claimableFilter, 
        activeFilter: $activeFilter, 
        code: $code
      }
    ) {
      nodes {
        id
        code
        startDate
        endDate
        discountValue
        discountType
        minRedeemValue
        minRedeemType
        pointUsed
        usedCount
        maxClaimed
      }
      pagesCount
      nodesCount
    }
  }
`

export default {
  GET_PROMOCODES
}
