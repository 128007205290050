var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "app-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.promoCodes.nodes,
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header.code",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "header.date",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "header.discountValue",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "header.minRedeemValue",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "header.pointUsed",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "header.usedCount",
              fn: function(ref) {
                var header = ref.header
                return [_vm._v(" " + _vm._s(header.text) + " ")]
              }
            },
            {
              key: "item.date",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("formatDateNoTime")(item.startDate)) +
                      " - " +
                      _vm._s(_vm._f("formatDateNoTime")(item.endDate)) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.discountValue",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(item.discountValue.toFixed(2)) + " "),
                  item.discountType == "baht"
                    ? _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("promocode.table.currency.baht")) +
                            " "
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("promocode.table.currency.percent")) +
                            " "
                        )
                      ])
                ]
              }
            },
            {
              key: "item.minRedeemValue",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center justify-space-between"
                    },
                    [
                      item.minRedeemValue || item.minRedeemValue === 0
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("promocode.table.minValue")) +
                                " " +
                                _vm._s(item.minRedeemValue.toFixed(2)) +
                                " "
                            ),
                            item.minRedeemType == "count_value"
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "promocode.table.minRedeem.value"
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "promocode.table.minRedeem.quantity"
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                          ])
                        : _c("span", [_vm._v(" ไม่มีขั้นต่ำ ")]),
                      _c("promocode-update", {
                        attrs: { "promo-id": item.id },
                        on: {
                          submit: function($event) {
                            return _vm.refetch()
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.pointUsed",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("promocode.table.used")) +
                      " " +
                      _vm._s(item.pointUsed.toFixed(2)) +
                      " " +
                      _vm._s(_vm.$t("promocode.table.point")) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.usedCount",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(" " + _vm._s(item.usedCount) + " / "),
                  item.maxClaimed
                    ? _c("span", { staticClass: "ml-2" }, [
                        _vm._v(" " + _vm._s(item.maxClaimed) + " ")
                      ])
                    : _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("promocode.table.infinite")) + " "
                        )
                      ])
                ]
              }
            },
            {
              key: "item.delete",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("promocode-remove", {
                    attrs: { "promo-id": item.id },
                    on: {
                      submit: function($event) {
                        return _vm.refetch()
                      }
                    }
                  })
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _c("v-pagination", {
        attrs: { length: _vm.promoCodes.pagesCount, "total-visible": 7 },
        model: {
          value: _vm.page,
          callback: function($$v) {
            _vm.page = $$v
          },
          expression: "page"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }