<template>
  <div class="promocode-update">
    <v-dialog
      v-model="dialog"
      max-width="500"
      @click:outside="closePromocodeUpdate()">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          v-on="on">
          <v-icon
            color="primary">
            mdi-square-edit-outline
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="pt-3">
          <div class="close-popup">
            <v-btn
              small
              icon
              @click="closePromocodeUpdate()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <title-label :label="$t('promocode.label.editMinValue')" />
          <v-form
            ref="form"
            v-model="valid"
            :readonly="loading"
            class="mt-4"
            lazy-validation
            @submit.prevent="submit()">
            <v-text-field
              v-model="promoCode.minRedeemValue"
              :suffix="$t('promocode.suffix')"
              :rules="minValueRules"
              class="minvalue-input mt-3"
              dense
              outlined
              required />
          </v-form>
          <div class="d-flex justify-end mt-4">
            <v-btn
              color="primary"
              text
              @click="closePromocodeUpdate()">
              {{ $t('promocode.btn.cancel') }}
            </v-btn>
            <save-change-button
              :state="state"
              :disabled="!valid"
              :label="$t('promocode.btn.confirmEdit')"
              class="mr-1"
              @click="submit()">
            </save-change-button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_PROMOCODE } from '@/resources/graphql/query/Promocode'
import { UPDATE_PROMOCODE } from '@/resources/graphql'

export default {
  name: 'PromocodeUpdate',
  apollo: {
    promoCode: () => ({
      fetchPolicy: 'no-cache',
      query: GET_PROMOCODE,
      variables () {
        return {
          id: this.promoId
        }
      }
    })
  },
  props: {
    promoId: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      dialog: false,
      promoCode: {},
      minValueRules: [
        (v) => !!v || this.$t('promocode.textField.minPrice.validate.required')
      ],
      valid: true,
      state: 'ready'
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    }
  },
  methods: {
    closePromocodeUpdate () {
      this.dialog = false
      this.$refs.form.resetValidation()
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: UPDATE_PROMOCODE,
          variables: {
            input: {
              id: this.promoId,
              minRedeemValue: Number(this.promoCode.minRedeemValue)
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          this.$emit('submit')
          this.state = 'success'
        }
        setTimeout(() => {
          this.state = 'ready'
        }, 3000)
      }
    }
  }
}
</script>

<style>
  .promocode-update .minvalue-input input {
    text-align: right !important;
  }
</style>
