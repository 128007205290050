<template>
  <div>
    <div class="d-flex align-center">
      <header-label
        :label="$t('promocode.header')"
        prepend-icon="$promocodeHeader" />
      <div class="mb-3">
        <v-icon color="primary">
          mdi-chevron-left
        </v-icon>
      </div>
      <span class="primary--text mb-3">
        {{ $t('shopsetting.header') }}
      </span>
    </div>
    <v-card flat>
      <v-card-text>
        <v-row
          class="mb-4"
          no-gutters>
          <v-col cols="4">
            <v-text-field
              v-model="code"
              :placeholder="$t('promocode.search.placeholder')"
              prepend-inner-icon="mdi-magnify"
              dense
              outlined
              hide-details />
          </v-col>
          <v-col class="pl-4">
            <v-select
              v-model="claimableFilter"
              :items="claimableEnum"
              item-text="description"
              item-value="name"
              prepend-inner-icon="mdi-filter"
              attach
              dense
              outlined
              hide-details>
            </v-select>
          </v-col>
          <v-col class="pl-4">
            <v-select
              v-model="activeFilter"
              :items="activeEnum"
              item-text="description"
              item-value="name"
              prepend-inner-icon="mdi-filter"
              attach
              dense
              outlined
              hide-details>
            </v-select>
          </v-col>
          <v-col class="text-right">
            <promocode-create @submit="refetchPromoCode()" />
          </v-col>
        </v-row>
        <promocode-data-table
          ref="PromoCodeDataTable"
          :claimable-filter="claimableFilter"
          :active-filter="activeFilter"
          :code="code">
        </promocode-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PromocodeDataTable from '@/views/ShopSetting/Pages/Promocode/components/PromocodeDataTable.vue'
import PromocodeCreate from '@/views/ShopSetting/Pages/Promocode/components/PomocodeCreate.vue'
import { GET_PROMOCODECLAIMABLEFILTER_ENUM, GET_PROMOCODEACTIVEFILTER_ENUM } from '@/resources/graphql'

export default {
  name: 'Promocode',
  apollo: {
    claimable: () => ({
      query: GET_PROMOCODECLAIMABLEFILTER_ENUM,
      result ({ data: { __type } }) {
        this.claimableEnum = __type.enumValues
      },
      update: (data) => data.__type
    }),
    active: () => ({
      query: GET_PROMOCODEACTIVEFILTER_ENUM,
      result ({ data: { __type } }) {
        this.activeEnum = __type.enumValues
      },
      update: (data) => data.__type
    })
  },
  components: {
    PromocodeDataTable,
    PromocodeCreate
  },
  data () {
    return {
      claimable: false,
      code: '',
      claimableFilter: 'all',
      claimableEnum: [],
      activeFilter: 'all',
      activeEnum: []
    }
  },
  methods: {
    refetchPromoCode () {
      this.$refs.PromoCodeDataTable.refetch()
    }
  }
}
</script>
